import { useEffect, useState } from "react";
import { Container, Box, TextField, Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import MarkdownEditor from "./components/MarkdownEditor";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc, updateDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
interface ArticleData {
  articleId: string;
  title: string;
  content: string;
}
function Article() {
  let params = useParams();
  const app = getApp();
  const auth = getAuth(app);
  const db = getFirestore(app);
  const [article, setArticle] = useState<ArticleData>({ articleId: "", title: "", content: "" });
  const [loading, setLoading] = useState(true);
  const [output, setOutput] = useState("");
  const [hidden, setHidden] = useState(false);
  let navigate = useNavigate();
  function handleSave() {
    if (auth.currentUser) {
      let userid = auth.currentUser.uid;
      const docRef = doc(db, `users/${userid}/articles/${params.id}`);
      // To update age and favorite color:
      updateDoc(docRef, {
        content: output,
        title: article.title,
        hidden: hidden,
        updateTimestamp: serverTimestamp(),
      });
    }
  }
  function handleDelete() {
    if (auth.currentUser) {
      let userid = auth.currentUser.uid;
      deleteDoc(doc(db, "users", userid, "articles", params.id as string)).then(() => {
        navigate("/home");
      });
    }
  }
  useEffect(() => {
    if (auth.currentUser) {
      let userid = auth.currentUser.uid;
      const docRef = doc(db, `users/${userid}/articles/${params.id}`);
      getDoc(docRef).then((doc) => {
        if (doc.exists()) {
          console.log("Document data:", doc.data());
          setArticle(doc.data() as ArticleData);
          setOutput(doc.data().content);
          setHidden(doc.data()?.hidden);
          setLoading(false);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
    }
  }, []);
  return (
    // <div style={{ maxWidth: "1200px", margin: "auto" }}>
    //   <MarkdownEditor />
    // </div>
    <Container>
      <Box sx={{ p: 4 }}>
        {loading ? null : (
          <>
            <TextField
              variant="standard"
              value={article.title}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setArticle({ ...article, title: event.target.value });
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={hidden}
                  onChange={(event) => {
                    setHidden(event.target.checked);
                  }}
                />
              }
              label="隱藏"
            />
            <MarkdownEditor title={article.title} content={article.content} articleId={article.articleId} setOutput={setOutput} />
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
              <Button variant="contained" onClick={handleSave}>
                儲存
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  navigate(-1);
                }}
              >
                返回
              </Button>
              <Button variant="contained" onClick={handleDelete} color="error">
                刪除
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </Container>
  );
}
export default Article;
