import StyledFirebaseAuth from "./components/StyledFirebaseAuth";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getApp } from "firebase/app";

function Login() {
  const app = getApp();
  const auth = getAuth(app);
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "/home",
    // We will display Google and Facebook as auth providers.
    signInOptions: [GoogleAuthProvider.PROVIDER_ID],
  };
  return (
    <div style={{ maxWidth: "1200px", margin: "auto" }}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </div>
  );
}
export default Login;
