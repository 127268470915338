import { useEffect, useState } from "react";
import { Editor, rootCtx, defaultValueCtx, editorViewOptionsCtx, ThemeFont, ThemeColor, getPalette } from "@milkdown/core";
import { nord } from "@milkdown/theme-nord";
import { ReactEditor, useEditor } from "@milkdown/react";
import { commonmark } from "@milkdown/preset-commonmark";
import { history } from "@milkdown/plugin-history";
import { listener, listenerCtx } from "@milkdown/plugin-listener";
import { menu, menuPlugin } from "@milkdown/plugin-menu";
import { useTheme } from "@mui/material/styles";
import "./MarkdownEditor.css";

interface MarkdownEditorProps {
  articleId: string;
  title: string;
  content: string;
  setOutput: (output: string) => void;
}

function MarkdownEditor({ title, content, articleId, setOutput }: MarkdownEditorProps) {
  // const [output, setOutput] = useState("");
  const [readonly, setReadonly] = useState(false);
  const theme = useTheme();
  const editable = () => !readonly;

  const CustomNord = nord.override((emotion, manager) => {
    // const palette = getPalette(manager);
    // manager.set(ThemeColor, ([key, opacity]) => {
    //   switch (key) {
    //     case "background":
    //       return theme.palette.background.default;
    //   }
    // });
    // console.log("background", palette("background"));
    manager.set(ThemeFont, (key) => {
      if (!key) return;
      switch (key) {
        case "typography":
          return theme.typography.fontFamily;
      }
    });
  });

  const { editor } = useEditor((root) =>
    Editor.make()
      .config((ctx) => {
        ctx.set(rootCtx, root);
        ctx.set(defaultValueCtx, content);
        ctx.get(listenerCtx).markdownUpdated((ctx, markdown, prevMarkdown) => {
          setOutput(markdown);
        });
        ctx.set(editorViewOptionsCtx, { editable });
      })
      .use(CustomNord)
      .use(commonmark)
      .use(history)
      .use(listener)
      .use(menu)
  );
  return <ReactEditor editor={editor} />;
}

export default MarkdownEditor;
