import { useEffect, useState } from "react";
import MarkdownEditor from "./components/MarkdownEditor";
import StyledFirebaseAuth from "./components/StyledFirebaseAuth";
import { GoogleAuthProvider, getAuth, onAuthStateChanged } from "firebase/auth";
import { getApp } from "firebase/app";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import "./App.css";

function App() {
  const app = getApp();
  const auth = getAuth(app);
  let navigate = useNavigate();
  let location = useLocation();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    console.log("location", location);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (location.pathname === "/") {
          setLoading(false);
          navigate("/home");
        }
        setLoading(false);
      } else {
        setLoading(false);
        navigate("/login");
      }
    });
  }, []);

  return <div className="App">{loading ? <></> : <Outlet />}</div>;
}

export default App;
