import { useEffect, useState } from "react";
import { Container, Box, Paper, Stack, Fab, Button, Link, Checkbox, FormControlLabel } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { getAuth, signOut } from "firebase/auth";
import { getApp } from "firebase/app";
import { getFirestore, collection, getDocs, addDoc, serverTimestamp, query, orderBy } from "firebase/firestore";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";

// const ArticleItem = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));
const testArticle = [
  {
    id: "1",
    title: "test1",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet. Tortor at risus viverra adipiscing at in tellus integer feugiat. Sit amet dictum sit amet justo. Pharetra sit amet aliquam id diam maecenas ultricies. Congue quisque egestas diam in arcu. Augue ut lectus arcu bibendum at varius vel pharetra vel. Aliquet eget sit amet tellus cras adipiscing enim. Sed faucibus turpis in eu mi. Est lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque. Est lorem ipsum dolor sit. Elit at imperdiet dui accumsan sit amet nulla facilisi morbi. Sed felis eget velit aliquet sagittis id.",
  },
  {
    id: "2",
    title: "test2",
    content:
      "Mattis rhoncus urna neque viverra justo nec ultrices. Aliquet bibendum enim facilisis gravida neque. Euismod quis viverra nibh cras. Nibh ipsum consequat nisl vel pretium lectus quam. Praesent tristique magna sit amet purus gravida. Turpis egestas pretium aenean pharetra magna. Arcu non odio euismod lacinia at quis risus sed. Libero justo laoreet sit amet. Sodales ut etiam sit amet. Sed vulputate mi sit amet mauris commodo quis imperdiet. Mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus. Metus aliquam eleifend mi in.",
  },
  {
    id: "3",
    title: "test3",
    content:
      "Nulla porttitor massa id neque aliquam. Ultrices mi tempus imperdiet nulla malesuada pellentesque. Phasellus faucibus scelerisque eleifend donec pretium. Morbi non arcu risus quis varius quam quisque id. Viverra aliquet eget sit amet tellus cras adipiscing enim. A cras semper auctor neque vitae tempus. Volutpat commodo sed egestas egestas fringilla phasellus. Molestie ac feugiat sed lectus vestibulum mattis. Odio ut enim blandit volutpat maecenas. Rhoncus dolor purus non enim praesent elementum. Pharetra magna ac placerat vestibulum lectus mauris. Integer enim neque volutpat ac tincidunt vitae. Odio tempor orci dapibus ultrices in iaculis nunc sed. Ut ornare lectus sit amet est. Vitae elementum curabitur vitae nunc sed velit dignissim. In vitae turpis massa sed elementum. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Nisi vitae suscipit tellus mauris. Enim nec dui nunc mattis enim.",
  },
];
interface ArticleData {
  articleId: string;
  title: string;
  content: string;
  hidden: boolean;
}
// type ArticleItemProps = {
//   content: string;
//   title: string;
// };

function Home() {
  const app = getApp();
  const auth = getAuth(app);
  const db = getFirestore(app);
  const [articles, setArticles] = useState<ArticleData[]>([]);
  const [showHidden, setShowHidden] = useState(true);
  useHotkeys("ctrl+shift+h", () => {
    console.log("shortcut hit", showHidden);
    setShowHidden(!showHidden);
  },[showHidden]);

  let navigate = useNavigate();
  useEffect(() => {
    if (auth.currentUser) {
      let userid = auth.currentUser.uid;
      let citiesRef = collection(db, "users", userid, "articles");
      const q = query(citiesRef, orderBy("createTimestamp", "desc"));

      // getDocs(collection(db, `users/${userid}/articles`)).then((querySnapshot) => {
      getDocs(q).then((querySnapshot) => {
        let tempArticles: ArticleData[] = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data() as ArticleData;
          data.articleId = doc.id;
          tempArticles.push(data);
          // console.log(data)
        });
        setArticles(tempArticles);
      });
    }
  }, []);
  function ArticleItem({ title, content, articleId, hidden }: ArticleData) {
    return (
      // <Paper sx={{ p: 2 }} hidden={showHidden ? false : hidden}>
      <Paper sx={{ p: 2 }} hidden={hidden ? showHidden : false}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Link sx={{ fontSize: "1.5rem" }} href={`/article/${articleId}`} underline="hover">
            {title}
          </Link>
          <Box>{content}</Box>
        </Box>
      </Paper>
    );
  }
  function handleAddArticle() {
    if (auth.currentUser) {
      let userid = auth.currentUser.uid;

      addDoc(collection(db, `users/${userid}/articles`), {
        title: "標題",
        content: "",
        hidden: false,
        createTimestamp: serverTimestamp(),
        updateTimestamp: serverTimestamp(),
      }).then((docRef) => {
        navigate(`/article/${docRef.id}`);
      });
    }
  }
  function handleSignout() {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Sign-out fail,", error);
      });
  }
  return (
    // <div style={{ maxWidth: "1200px", margin: "auto" }}>
    //   <MarkdownEditor />
    // </div>
    <Container>
      <Box sx={{ p: 4 }}>
        {/* <FormControlLabel
          control={
            <Checkbox
              value={showHidden}
              onChange={(event) => {
                setShowHidden(event.target.checked);
              }}
            />
          }
          label="顯示隱藏"
        /> */}
        <Stack spacing={2}>
          {articles.map((article) => (
            <ArticleItem
              key={article.articleId}
              articleId={article.articleId}
              title={article.title}
              content={article.content}
              hidden={article.hidden}
            />
          ))}
        </Stack>
        <Fab color="primary" aria-label="add" onClick={handleAddArticle}>
          <AddIcon />
        </Fab>
        <Button onClick={handleSignout}>Sign out</Button>
      </Box>
    </Container>
  );
}
export default Home;
