import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Home from "./Home";
import Article from "./Article";
import Login from "./Login";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import paperBackground from "./handmade-paper.png";

const firebaseConfig = {
  apiKey: "AIzaSyCwtWjxJgkSsBkNzuY76Xs4B_PLfELywrc",
  authDomain: "blog-225d4.firebaseapp.com",
  projectId: "blog-225d4",
  storageBucket: "blog-225d4.appspot.com",
  messagingSenderId: "1030950631765",
  appId: "1:1030950631765:web:f2ade060517b3070a1d7dc",
};
const theme = createTheme({
  palette: {
    mode: "dark",
  },
  shape:{
    borderRadius: 8
  },
  typography:{
    // fontFamily: "'Shippori Mincho B1', serif"
    fontFamily: "'Noto Serif TC', serif",
    button:{
      fontSize:"1rem",
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      body {
          background-image: url(${paperBackground});
        }
      `,
    },
  },
});
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="home" element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="article/:id" element={<Article />} />
          </Route>
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
